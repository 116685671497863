/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://ifvpnk3wcnczvozph76wmjftai.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-55dwug4qgjforlvzxz7r2hliiq",
    "aws_cognito_identity_pool_id": "eu-west-2:258701c5-8ce8-4ad9-aac4-2e29c7399e27",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_MKzuJc8D3",
    "aws_user_pools_web_client_id": "6ga4opmmeaeqqhlkkk7al2ofn3",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "gfsdashtransactions65aec-gfsdashiot",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
