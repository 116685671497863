/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGfsfill = /* GraphQL */ `
  query GetGfsfill($id: ID!) {
    getGfsfill(id: $id) {
      id
      createdAt
      updatedAt
      TransactNo
      Success
      ScaleName
      ScaleSSID
      TimeStart
      TimeStop
      FillTime
      Invoice
      ScaleType
      CylSelect
      ForkLift
      CylSize
      TareLow
      TareHigh
      ShutOff
      TareWeight
      FillKgs
      Residual
      __typename
    }
  }
`;
export const listGfsfills = /* GraphQL */ `
  query ListGfsfills(
    $filter: ModelGfsfillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGfsfills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        TransactNo
        Success
        ScaleName
        ScaleSSID
        TimeStart
        TimeStop
        FillTime
        Invoice
        ScaleType
        CylSelect
        ForkLift
        CylSize
        TareLow
        TareHigh
        ShutOff
        TareWeight
        FillKgs
        Residual
        __typename
      }
      nextToken
      __typename
    }
  }
`;
