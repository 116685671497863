// @mui material components
import Icon from "@mui/material/Icon";

//import React from "react";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Amplify } from "aws-amplify";

import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import config from "../../../../src/amplifyconfiguration.json";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import LogoAsana from "assets/images/small-logos/logo-asana.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";

const API_URL = "https://ws6q5d3zf3.execute-api.eu-west-2.amazonaws.com/prod";

const ProjectsTable = () => {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const fetchDeviceStatus = async () => {
      try {
        const response = await fetch(API_URL);
        const data = await response.json();
        setDevices(data); // Update state with fetched devices
        console.log(data);
      } catch (error) {
        console.error("Error fetching device status:", error);
      }
    };

    fetchDeviceStatus();
  }, []);

  return (
    <div className="small">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Device Name</th>
            <th>Scale Name</th>
            <th>Connected</th>
            <th>Last Connected</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device, index) => (
            <tr key={device.id || index}>
              <td>{device.thingName}</td>
              <td>{device.thingName}</td>
              <td>{device.connected ? "🟢 Online" : "🔴 Offline"}</td>
              <td>{device.lastSeen}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ProjectsTable;
