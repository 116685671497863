// layouts/authentication/sign-out.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Amplify } from "aws-amplify"; // Correct import
import { CircularProgress } from "@mui/material";
import { MaterialUIControllerProvider } from "context";
import { signOut } from "aws-amplify/auth";

function UserLogOut() {
  try {
    signOut({ global: true });
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

export default UserLogOut;
