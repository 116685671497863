import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import config from "../../../../src/amplifyconfiguration.json";
import { generateClient } from "aws-amplify/api";
import { listGfsfills } from "../../../graphql/queries";
import { onCreateGfsfill, onUpdateGfsfill } from "../../../graphql/subscriptions";

Amplify.configure(config);
const client = generateClient();

const TransactionsTable = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    async function fetchInitialData() {
      setLoading(true); // Set loading to true while fetching
      try {
        const gasFills = await client.graphql({ query: listGfsfills });
        setItems(gasFills.data.listGfsfills.items);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false); // Set loading to false after fetch (success or error)
      }
    }

    fetchInitialData();

    // Subscribe to updates
    const subscriptionUpdate = client.graphql({ query: onUpdateGfsfill }).subscribe({
      next: (gasFillData) => {
        const newGasFill = gasFillData.data.onUpdateGfsfill;
        setItems((prevItems) => [...prevItems, newGasFill]);
        console.log("Gas Fill Updated:", newGasFill);
      },
      error: (error) => {
        console.error("subscriptionUpdate error:", error);
      },
    });

    // Subscribe to new creates
    const subscriptionCreate = client.graphql({ query: onCreateGfsfill }).subscribe({
      next: (gasFillData) => {
        const newGasFill = gasFillData.data.onCreateGfsfill;
        setItems((prevItems) => [...prevItems, newGasFill]);
        console.log("New Gas Fill added:", newGasFill);
      },
      error: (error) => {
        console.error("subscriptionCreate error:", error);
      },
    });

    // Cleanup on component unmount
    return () => {
      subscriptionUpdate.unsubscribe();
      subscriptionCreate.unsubscribe();
    };
  }, []); // Empty dependency array ensures this runs only on mount

  if (loading) {
    return <div>Loading Scale Transactions...</div>;
  }

  return (
    <div className="small">
      <Table striped bordered hover responsive>
        {" "}
        {/* Added some styling */}
        <thead>
          <tr>
            <th>TransactNo</th>
            <th>Scale Name</th>
            <th>CylSize</th>
            <th>FillKgs</th>
            <th>Invoice</th>
            <th>TareWeight</th>
            <th>Date-TimeStart</th>
            <th>Date-TimeStop</th>
            <th>FillingTime</th>
            <th>TareHigh</th>
            <th>TareLow</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={item.id || index}>
              {" "}
              {/* Use item.id if available, otherwise index */}
              <td>{item.TransactNo}</td>
              <td>{item.ScaleName}</td>
              <td>{item.CylSize}</td>
              <td>{item.FillKgs} kg</td>
              <td>{item.Invoice}</td>
              <td>{item.TareWeight} kg</td>
              <td>{item.TimeStart}</td>
              <td>{item.TimeStop}</td>
              <td>{item.FillTime}</td>
              <td>{item.TareHigh} kg</td>
              <td>{item.TareLow} kg</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TransactionsTable;
