/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateGfsfill = /* GraphQL */ `
  subscription OnCreateGfsfill($filter: ModelSubscriptionGfsfillFilterInput) {
    onCreateGfsfill(filter: $filter) {
      id
      createdAt
      updatedAt
      TransactNo
      Success
      ScaleName
      ScaleSSID
      TimeStart
      TimeStop
      FillTime
      Invoice
      ScaleType
      CylSelect
      ForkLift
      CylSize
      TareLow
      TareHigh
      ShutOff
      TareWeight
      FillKgs
      Residual
      __typename
    }
  }
`;
export const onUpdateGfsfill = /* GraphQL */ `
  subscription OnUpdateGfsfill($filter: ModelSubscriptionGfsfillFilterInput) {
    onUpdateGfsfill(filter: $filter) {
      id
      createdAt
      updatedAt
      TransactNo
      Success
      ScaleName
      ScaleSSID
      TimeStart
      TimeStop
      FillTime
      Invoice
      ScaleType
      CylSelect
      ForkLift
      CylSize
      TareLow
      TareHigh
      ShutOff
      TareWeight
      FillKgs
      Residual
      __typename
    }
  }
`;
export const onDeleteGfsfill = /* GraphQL */ `
  subscription OnDeleteGfsfill($filter: ModelSubscriptionGfsfillFilterInput) {
    onDeleteGfsfill(filter: $filter) {
      id
      createdAt
      updatedAt
      TransactNo
      Success
      ScaleName
      ScaleSSID
      TimeStart
      TimeStop
      FillTime
      Invoice
      ScaleType
      CylSelect
      ForkLift
      CylSize
      TareLow
      TareHigh
      ShutOff
      TareWeight
      FillKgs
      Residual
      __typename
    }
  }
`;
